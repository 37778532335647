import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL, // Now using REACT_APP_BASE_URL
});
const mockApi = axios.create({
    baseURL: process.env.REACT_APP_MOCK_API_URL, // Mock API URL
    headers: {
        "Content-Type": "application/json",
    },
});

export { api, mockApi };