import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MDBContainer, MDBInput, MDBBtn } from 'mdb-react-ui-kit';
import SideNavbar from '../../SideNavbar';
import TopNavbar from '../../TopNavbar';
import { api } from '../../../services';

function AdminAddApp() {
    const [form, setForm] = useState({
        name: '',
        description: '',
        logo: '',
        apiUrl: '',
        status: 'Active', // Default status
    });
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setForm((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async () => {
        // Validation: Ensure required fields are provided
        if (!form.name || !form.apiUrl || !form.status) {
            setError('Name, API URL, and Status are required.');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            await api.post('/admin/available-apps', form, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setSuccessMessage('App added successfully!');
            setError('');
            setForm({ name: '', description: '', logo: '', apiUrl: '', status: 'Active' }); // Reset form
            setTimeout(() => {
                navigate('/admin/available-apps'); // Redirect to app management page after success
            }, 2000);
        } catch (error) {
            console.error('Error adding app:', error.message);
            setError('Failed to add app. Please try again.');
        }
    };

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2>Add New App</h2>
                    <MDBInput
                        label="App Name"
                        name="name"
                        value={form.name}
                        onChange={handleFormChange}
                        className="mb-3"
                        required
                    />
                    <MDBInput
                        label="Description"
                        name="description"
                        value={form.description}
                        onChange={handleFormChange}
                        className="mb-3"
                    />
                    <MDBInput
                        label="Logo URL"
                        name="logo"
                        value={form.logo}
                        onChange={handleFormChange}
                        className="mb-3"
                    />
                    <MDBInput
                        label="API URL"
                        name="apiUrl"
                        value={form.apiUrl}
                        onChange={handleFormChange}
                        className="mb-3"
                        required
                    />
                    <select
                        name="status"
                        value={form.status}
                        onChange={handleFormChange}
                        className="form-select mb-3"
                        required
                    >
                        <option value="Active">Active</option>
                        <option value="Coming Soon">Coming Soon</option>
                        <option value="Pending">Pending</option>
                        <option value="Inactive">Inactive</option>
                    </select>
                    {error && <p className="text-danger">{error}</p>}
                    {successMessage && <p className="text-success">{successMessage}</p>}
                    <MDBBtn color="primary" onClick={handleSubmit}>
                        Add App
                    </MDBBtn>
                </MDBContainer>
            </div>
        </>
    );
}

export default AdminAddApp;
