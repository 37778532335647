import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardText, MDBBtn, MDBBadge } from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import { api } from '../services';

function AppManagement() {
    const [apps, setApps] = useState([]); // User-integrated apps
    const [availableApps, setAvailableApps] = useState([]); // Available apps for integration
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchApps();
    }, []);

    const fetchApps = async () => {
        try {
            const token = localStorage.getItem('token');
    
            // Fetch integrated apps
            const integratedAppsResponse = await api.get('/apps', {
                headers: { Authorization: `Bearer ${token}` },
            });
            const integratedApps = integratedAppsResponse.data.apps || [];
            setApps(integratedApps);
    
            // Fetch all available apps
            const availableAppsResponse = await api.get('/available-apps', {
                headers: { Authorization: `Bearer ${token}` },
            });
            const allAvailableApps = availableAppsResponse.data.availableApps || [];
    
            // Filter available apps: exclude already integrated and inactive apps, adjust status for pending
            const integratedAppIds = integratedApps.map((app) => app._id);
            const filteredAvailableApps = allAvailableApps.filter((app) => {
                if (integratedAppIds.includes(app._id)) {
                    return false; // Exclude already integrated apps
                }
                if (app.status === 'Inactive') {
                    return false; // Exclude inactive apps
                }
                return true;
            }).map((app) => {
                if (app.status === 'Pending') {
                    return { ...app, status: 'Pending Approval' }; // Rename status for Pending apps
                }
                return app;
            });
    
            setAvailableApps(filteredAvailableApps);
        } catch (error) {
            console.error('Error fetching apps:', error.message);
        }
    };
    
    // Handle integration of new apps
    const handleAddNewApp = (appId, isDemo = false) => {
        if (isDemo) {
            handleIntegrateDemoApp(appId);
        } else {
            navigate('/add-app', { state: { appId } });
        }
    };

    // Automatically integrate the Demo App
    const handleIntegrateDemoApp = async (appId) => {
        try {
            const token = localStorage.getItem('token');
            await api.post('/apps/demo-integrate', { appId }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            fetchApps(); // Refresh the apps list
        } catch (error) {
            console.error('Error integrating Demo App:', error.message);
        }
    };

    const handleDisconnectApp = async (appId, isDemo = false) => {
        try {
            const token = localStorage.getItem('token');
            const url = isDemo ? '/apps/demo' : `/apps/${appId}`;
            const response = await api.delete(url, {
                headers: { Authorization: `Bearer ${token}` },
            });

            alert(response.data.message || 'App disconnected successfully.');
            fetchApps(); // Refresh the apps list
        } catch (error) {
            console.error('Error disconnecting app:', error.response?.data || error.message);
            alert(error.response?.data?.message || 'Failed to disconnect the app. Please try again.');
        }
    };


    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2 className="mb-4">Apps Integration</h2>

                   {/* Available Apps Section */}
                    <h5>Available Apps</h5>
                    <MDBRow className="mb-4">
                        {availableApps.map((app) => (
                            <MDBCol className="mb-4" md="6" key={app._id}>
                                <MDBCard className="h-100">
                                    <MDBCardBody>
                                        <MDBCardText>
                                            <strong>{app.name}</strong>
                                        </MDBCardText>
                                        <p>{app.description}</p>

                                        {/* Button or badge based on app status */}
                                        {app.status === 'Pending Approval' ? (
                                            <MDBBadge color="warning" className="mt-2">Pending Approval</MDBBadge>
                                        ) : app.status === 'Coming Soon' ? (
                                            <MDBBadge color="secondary" className="mt-2">Coming Soon</MDBBadge>
                                        ) : (
                                            <MDBBtn
                                                color={app.isDemo ? 'warning' : 'info'}
                                                outline
                                                size="sm"
                                                onClick={() => handleAddNewApp(app._id, app.isDemo)}
                                            >
                                                {app.isDemo ? 'Explore Demo' : 'Integrate'}
                                            </MDBBtn>
                                        )}
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        ))}
                    </MDBRow>

                    {/* Integrated Apps Section */}
                    <h5>Integrated Apps</h5>
                    <MDBRow>
                        {apps.map((app) => (
                            <MDBCol className="mb-4" md="4" key={app._id}>
                                <MDBCard className="h-100">
                                    <MDBCardBody>
                                        <MDBCardText>
                                            <strong>{app.application}</strong>
                                        </MDBCardText>
                                        {app.isDemo && <p className="text-warning">Demo App</p>}

                                        {/* Show different statuses with conditions */}
                                        {app.status === 'pending' ? (
                                            <MDBBadge color="warning" className="mt-2">Pending Approval</MDBBadge>
                                        ) : app.status === 'connected' ? (
                                            <MDBBadge color="success" className="mt-2">Connected</MDBBadge>
                                        ) : (
                                            <MDBBadge color="secondary" className="mt-2">{app.status}</MDBBadge>
                                        )}

                                        {/* Show Disconnect button only for connected apps */}
                                        {app.status === 'connected' && (
                                            <MDBBtn
                                                color="danger"
                                                outline
                                                size="sm"
                                                disabled={loading}
                                                onClick={() => handleDisconnectApp(app._id, app.isDemo)} // Pass isDemo flag
                                            >
                                                {app.isDemo ? 'Disconnect Demo' : 'Disconnect'}
                                            </MDBBtn>
                                        )}
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        ))}
                    </MDBRow>

                </MDBContainer>
            </div>
        </>
    );
}

export default AppManagement;
