import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBBtn,
} from 'mdb-react-ui-kit';
import { api } from '../services';

function ViewOrderDetails() {
    const { orderId } = useParams(); // Get order ID from the route
    const [orderDetails, setOrderDetails] = useState(null);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchOrderDetails();
    }, []);

    const fetchOrderDetails = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get(`/orders/${orderId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setOrderDetails(response.data);
        } catch (err) {
            setError(err.response?.data?.message || 'Failed to fetch order details.');
        }
    };

    const handleBack = () => {
        navigate('/orders'); // Navigate back to the orders list
    };

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2 className="mb-4">Order Details</h2>
                    {error ? (
                        <p className="text-danger">{error}</p>
                    ) : orderDetails ? (
                        <MDBCard>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol md="6">
                                        <h5>Order Information</h5>
                                        <p><strong>Order ID:</strong> {orderDetails.order_id}</p>
                                        <p><strong>Customer Name:</strong> {orderDetails.customer_name}</p>
                                        <p><strong>Platform:</strong> {orderDetails.platform}</p>
                                        <p><strong>Status:</strong> {orderDetails.status}</p>
                                        <p><strong>Total Price:</strong> ${orderDetails.total_price}</p>
                                    </MDBCol>
                                    <MDBCol md="6">
                                        <h5>Additional Details</h5>
                                        <p><strong>Created At:</strong> {new Date(orderDetails.createdAt).toLocaleString()}</p>
                                        <p><strong>Email:</strong> {orderDetails.customer_email || 'N/A'}</p>
                                        <p><strong>Phone:</strong> {orderDetails.customer_phone || 'N/A'}</p>
                                    </MDBCol>
                                </MDBRow>

                                <h5 className="mt-4">Order Items</h5>
                                <MDBTable>
                                     <MDBTableHead className="table-header">
                                        <tr>
                                            <th className="custom-th">Item</th>
                                            <th className="custom-th">Price</th>
                                            <th className="custom-th">Quantity</th>
                                            <th className="custom-th">Total</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {orderDetails.items.map((item, idx) => (
                                            <tr key={idx}>
                                                <td>{item.name}</td>
                                                <td>${item.price.toFixed(2)}</td>
                                                <td>{item.quantity}</td>
                                                <td>${(item.price * item.quantity).toFixed(2)}</td>
                                            </tr>
                                        ))}
                                    </MDBTableBody>
                                </MDBTable>

                                <MDBRow className="mt-4">
                                    <MDBCol>
                                        <MDBBtn color="secondary" outline onClick={handleBack}>
                                            Back to Orders
                                        </MDBBtn>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    ) : (
                        <p>Loading...</p>
                    )}
                </MDBContainer>
            </div>
        </>
    );
}

export default ViewOrderDetails;
