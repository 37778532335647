// TwoFactorAuth.js
import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBCard, MDBCardBody, MDBCardImage, MDBInput, MDBBtn, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import SideNavbar from './SideNavbar';
import TopNavbar from './TopNavbar';
import { api } from '../services';

function TwoFactorAuth() {
    const [qrCode, setQrCode] = useState('');
    const [secret, setSecret] = useState('');
    const [token, setToken] = useState('');
    const [setupCompleted, setSetupCompleted] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const setup2FA = async () => {
            try {
                const response = await api.post('/2fa/setup');
                setQrCode(response.data.qrCodeDataURL);
                setSecret(response.data.secret);
            } catch (error) {
                setError("Failed to generate 2FA QR Code.");
                console.error("Error setting up 2FA:", error);
            }
        };
        setup2FA();
    }, []);

    const handleVerify = async () => {
        try {
            const response = await api.post('/2fa/verify', { token });
            setMessage(response.data.message);
            setSetupCompleted(true); // Mark setup as complete if successful
            setError('');
        } catch (error) {
            setError("Invalid 2FA token.");
            setMessage('');
            console.error("Error verifying 2FA:", error);
        }
    };

    return (
        <>
            <TopNavbar />
            <div className="d-flex">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area">
                    <MDBRow className="justify-content-center">
                        <MDBCol md="6">
                            <MDBCard>
                                <MDBCardBody>
                                    <h3>Two-Factor Authentication</h3>
                                    {error && <p className="text-danger">{error}</p>}
                                    {message && <p className="text-success">{message}</p>}

                                    {!setupCompleted ? (
                                        <>
                                            <div className="mb-4">
                                                <h5>Step 1: Setup 2FA</h5>
                                                {qrCode && (
                                                    <>
                                                        <p>Scan the QR code with your authenticator app:</p>
                                                        <MDBCardImage src={qrCode} alt="QR Code" className="img-fluid mb-3" />
                                                        <p><strong>Secret:</strong> {secret}</p>
                                                    </>
                                                )}
                                            </div>
                                            <div className="mb-4">
                                                <h5>Step 2: Verify 2FA</h5>
                                                <MDBInput
                                                    label="Enter 2FA Code"
                                                    type="text"
                                                    value={token}
                                                    onChange={(e) => setToken(e.target.value)}
                                                    wrapperClass="mb-4"
                                                />
                                                <MDBBtn onClick={handleVerify}>Verify</MDBBtn>
                                            </div>
                                        </>
                                    ) : (
                                        <p className="text-success">Two-Factor Authentication setup completed successfully!</p>
                                    )}
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        </>
    );
}

export default TwoFactorAuth;
