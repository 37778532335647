import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logos/logo.png';
import {
    MDBBtn,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBInput,
    MDBCardFooter,
    MDBCardHeader,
} from 'mdb-react-ui-kit';
import { api } from '../services';

function Register() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    // const handleRegister = async () => {
    //     // Check if passwords match before making the API call
    //     if (password !== confirmPassword) {
    //         setError("Passwords do not match.");
    //         return;
    //     }
    //     try {
    //         // Adding user_type as 'company' by default
    //         const response = await api.post('/register', { email, phone, password, user_type: 'company' });
    //         console.log("Registration successful:", response.data);
    //         navigate('/'); // Redirect to login after successful registration
    //     } catch (error) {
    //         console.error("Error during registration:", error.response?.data?.message || error.message);
    //         setError(error.response?.data?.message || "An error occurred during registration.");
    //     }
    // };

    const handleRegister = async () => {
        // Check if passwords match before making the API call
        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }
        try {
            // Adding user_type as 'company' by default
            const response = await api.post('/register', { email, phone, password, user_type: 'company' });
            console.log("Registration successful:", response.data);
            navigate('/'); // Redirect to login after successful registration
        } catch (error) {
            console.error("Error during registration:", error.response?.data?.message || error.message);
    
            // Display specific error messages based on the response
            if (error.response?.data?.message.includes('email')) {
                setError("User already registered.");
            } else if (error.response?.data?.message.includes('phone')) {
                setError("User already registered.");
            } else {
                setError(error.response?.data?.message || "An error occurred during registration.");
            }
        }
    };
    

    return (
        <MDBContainer fluid className="p-5 d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
            <MDBCard style={{ maxWidth: "500px", width: "100%" }}>
                <MDBCardHeader className="d-flex justify-content-center align-items-center p-4">
                    <MDBCardImage src={logo} alt="logo" style={{ maxWidth: "150px", width: "100%" }} />
                </MDBCardHeader>

                <MDBCardBody>
                    <MDBInput
                        wrapperClass='mb-4'
                        label='Email address'
                        id='register-email'
                        type='email'
                        size="lg"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <MDBInput
                        wrapperClass='mb-4'
                        label='Phone Number'
                        id='register-phone'
                        type='tel'
                        size="lg"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <MDBInput
                        wrapperClass='mb-4'
                        label='Password'
                        id='register-password'
                        type='password'
                        size="lg"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <MDBInput
                        wrapperClass='mb-4'
                        label='Confirm Password'
                        id='confirm-password'
                        type='password'
                        size="lg"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <div className="d-grid gap-2 col-6 mx-auto">
                        <MDBBtn className="button-primary mb-4 px-5" size='lg' onClick={handleRegister}>Register</MDBBtn>
                    </div>
                    {error && <p className="text-danger">{error}</p>}
                    <p className="mb-5 pb-lg-2" style={{ color: 'var(--color-primary)' }}>
                        Already have an account? <a href="/?login=true" style={{ color: 'var(--color-secondary)' }}>Login here</a>
                    </p>
                </MDBCardBody>

                <MDBCardFooter className='text-muted d-flex justify-content-center'>
                    <a href="#!" className="small text-muted me-1">Terms of use.</a>
                    <a href="#!" className="small text-muted">Privacy policy</a>
                </MDBCardFooter>
            </MDBCard>
        </MDBContainer>
    );
}

export default Register;
