import React, { useState } from 'react';
import {
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBInput,
    MDBBtn,
    MDBCardHeader,
} from 'mdb-react-ui-kit';
import { api } from '../services';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleForgotPassword = async () => {
        try {
            const response = await api.post('/forgot-password', { email });
            setMessage(response.data.message);
            setError('');
        } catch (error) {
            setError(error.response?.data?.message || 'An error occurred.');
            setMessage('');
        }
    };

    return (
        <MDBContainer fluid className="p-5 d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
            <MDBCard style={{ maxWidth: "500px", width: "100%" }}>
                <MDBCardHeader className="d-flex justify-content-center align-items-center p-4">
                    <h2>Forgot Password</h2>
                </MDBCardHeader>
                <MDBCardBody>
                    <MDBInput
                        wrapperClass="mb-4"
                        label="Email address"
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <MDBBtn onClick={handleForgotPassword}>Send Reset Link</MDBBtn>
                    {message && <p className="text-success mt-3">{message}</p>}
                    {error && <p className="text-danger mt-3">{error}</p>}
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
}

export default ForgotPassword;
