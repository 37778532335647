import React, { useState, useEffect } from 'react';
import {
    Line as LineChart,
    Bar as BarChart,
} from 'react-chartjs-2'; // Import charts
import 'chart.js/auto'; // Required for Chart.js integration
import TopNavbar from '../TopNavbar'; // Adjust import path as needed
import SideNavbar from '../SideNavbar'; // Adjust import path as needed
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBIcon,
    MDBInputGroup,
    MDBInput,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
} from 'mdb-react-ui-kit';
import { api } from '../../services';

function AdminDashboard() {
    const [search, setSearch] = useState('');
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [chartData, setChartData] = useState(null); // Chart data state
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchUsers() {
            try {
                const token = localStorage.getItem('token');
                const response = await api.get('/admin/users', {
                    headers: { Authorization: `Bearer ${token}` },
                });

                const nonAdminUsers = response.data.users.filter(user => user.user_type !== 'admin');
                setUsers(nonAdminUsers);
                setFilteredUsers(nonAdminUsers);
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setLoading(false);
            }
        }

        fetchUsers();
    }, []);

    useEffect(() => {
        // Filter users based on the search query
        setFilteredUsers(
            users.filter(user =>
                (user.username || '').toLowerCase().includes(search.toLowerCase()) ||
                (user.email || '').toLowerCase().includes(search.toLowerCase())
            )
        );
    }, [search, users]);

    // Fetch data for the chart
    useEffect(() => {
        async function fetchChartData() {
            try {
                const response = await api.get('/demo-api/metrics'); // Replace with your demo API
                const data = response.data; // Assuming the API response has suitable data

                setChartData({
                    labels: data.map(item => item.date), // Extract dates
                    datasets: [
                        {
                            label: 'User Activity',
                            data: data.map(item => item.value), // Extract activity values
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            borderColor: 'rgba(75, 192, 192, 1)',
                            borderWidth: 1,
                        },
                    ],
                });
            } catch (error) {
                console.error('Error fetching chart data:', error);
            }
        }

        fetchChartData();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h2>Admin Dashboard</h2>
                        <MDBInputGroup className="search-input" style={{ maxWidth: '300px' }}>
                            <MDBInput
                                type="text"
                                placeholder="Search Users"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                style={{ borderRadius: '8px' }}
                            />
                        </MDBInputGroup>
                    </div>

                    {/* Statistics Section */}
                    <MDBRow className="mb-4 g-4">
                        <MDBCol md="3">
                            <MDBCard className="stat-card h-100">
                                <MDBCardBody className="d-flex justify-content-between align-items-start">
                                    <div>
                                        <h6>Total Users</h6>
                                        <h3 className="mb-0">{filteredUsers.length}</h3>
                                    </div>
                                    <MDBIcon fas icon="users" className="stat-icon" />
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>

                    {/* Chart Section */}
                    {chartData && (
                        <MDBRow className="mb-4">
                            <MDBCol md="12">
                                <MDBCard>
                                    <MDBCardBody>
                                        <h5 className="mb-4">User Activity</h5>
                                        <LineChart data={chartData} />
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                    )}

                    {/* User Data Table */}
                    <MDBTable striped hover responsive className="mt-4">
                        <MDBTableHead className="table-header">
                            <tr>
                                <th className="custom-th">Name</th>
                                <th className="custom-th">Email</th>
                                <th className="custom-th">Phone</th>
                                <th className="custom-th">Joining Date</th>
                                <th className="custom-th">Status</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody className="table-row">
                            {filteredUsers.map((user) => (
                                <tr key={user._id}>
                                    <td>{user.username}</td>
                                    <td>{user.email}</td>
                                    <td>{user.phone}</td>
                                    <td>{new Date(user.createdAt).toLocaleDateString()}</td>
                                    <td>{user.status || 'Active'}</td>
                                </tr>
                            ))}
                        </MDBTableBody>
                    </MDBTable>
                </MDBContainer>
            </div>
        </>
    );
}

export default AdminDashboard;
