import React, { useState } from 'react';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBBtn,
    MDBInput,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBCard,
    MDBCardBody
} from 'mdb-react-ui-kit';

function AppsPage() {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [sortOrder, setSortOrder] = useState('recent'); // Sorting order

    const appsData = [
        { id: 1, appName: "Door Dash", catalogName: "Breakfast Catalog", commissionRate: "5%", dateAdded: "2023-12-01" },
        { id: 2, appName: "Zomato", catalogName: "Catalog 2", commissionRate: "6%", dateAdded: "2023-11-25" },
        { id: 3, appName: "Deliveroo", catalogName: "Breakfast Catalog", commissionRate: "11%", dateAdded: "2023-11-30" },
        { id: 4, appName: "Hunger Station", catalogName: "Catalog 3", commissionRate: "12%", dateAdded: "2023-11-20" },
    ];

    // Filter function must be defined here before invocation
    const filterApps = () => {
        let updatedApps = [...appsData];

        // Search filter
        if (searchQuery) {
            updatedApps = updatedApps.filter((app) =>
                app.appName.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        // Date filter
        if (selectedDate) {
            updatedApps = updatedApps.filter(
                (app) => app.dateAdded === selectedDate
            );
        }

        // Sorting
        updatedApps.sort((a, b) => {
            if (sortOrder === 'recent') {
                return new Date(b.dateAdded) - new Date(a.dateAdded);
            } else {
                return new Date(a.dateAdded) - new Date(b.dateAdded);
            }
        });

        return updatedApps;
    };

    const filteredApps = filterApps(); // Invoke the filter function here

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2 className="mb-4" style={{ color: "#333" }}>All Apps</h2>

                    {/* Filters Section */}
                    <MDBRow className="align-items-center mb-4">
                        {/* Search Bar */}
                        <MDBCol md="3">
                            <MDBInput
                                type="text"
                                placeholder="Search Apps"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                style={{ borderRadius: "8px" }}
                            />
                        </MDBCol>

                        {/* Date Filter */}
                        <MDBCol md="3">
                            <MDBInput
                                type="date"
                                label="Filter by Date"
                                value={selectedDate}
                                onChange={(e) => setSelectedDate(e.target.value)}
                                style={{ borderRadius: "8px" }}
                            />
                        </MDBCol>

                        {/* Sort Dropdown */}
                        <MDBCol md="3">
                            <MDBDropdown>
                                <MDBDropdownToggle color="light">Sort by</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem onClick={() => setSortOrder('recent')}>
                                        Recent
                                    </MDBDropdownItem>
                                    <MDBDropdownItem onClick={() => setSortOrder('oldest')}>
                                        Oldest
                                    </MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>

                        {/* Add App Button */}
                        <MDBCol md="3" className="d-flex justify-content-end">
                            <MDBBtn color="primary" style={{ borderRadius: "8px" }}>
                                + Add App
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>

                    {/* Apps Table */}
                    <MDBTable responsive>
                    <MDBTableHead className="table-header">
                                    <tr>
                                        <th className="custom-th">App Name</th>
                                        <th className="custom-th">Catalog Name</th>
                                        <th className="custom-th">Commission Rate</th>
                                        <th className="custom-th">Actions</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody className="table-row">
                                    {filteredApps.map((app) => (
                                        <tr key={app.id}>
                                            <td>{app.appName}</td>
                                            <td>{app.catalogName}</td>
                                            <td>{app.commissionRate}</td>
                                            <td>
                                                <MDBBtn
                                                    color="secondary"
                                                    outline
                                                    size="sm"
                                                    style={{ marginRight: "8px" }}
                                                >
                                                    Edit
                                                </MDBBtn>
                                                <MDBBtn color="danger" outline size="sm">
                                                    Delete
                                                </MDBBtn>
                                            </td>
                                        </tr>
                                    ))}
                                </MDBTableBody>
                            </MDBTable>
                </MDBContainer>
            </div>
        </>
    );
}

export default AppsPage;
