import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBBtn,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBInput,
} from 'mdb-react-ui-kit';
import { api } from '../services';

function ManageBranches() {
    const location = useLocation();
    const navigate = useNavigate();
    const { storeId, storeName } = location.state || {};
    const [branches, setBranches] = useState([]);
    const [admin, setAdmin] = useState({});
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        if (storeId) fetchStoreDetails();
    }, [storeId]);

    const fetchStoreDetails = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get(`/stores/${storeId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setBranches(response.data.branches || []);
            setAdmin(response.data.admin || {});
        } catch (error) {
            console.error('Error fetching store details:', error.response?.data || error.message);
        }
    };

    const handleAddBranch = () => {
        navigate('/add-branch', { state: { storeId, storeName } });
    };

    const filteredBranches = branches.filter((branch) =>
        branch.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2 className="mb-4">{storeName} - Branches</h2>

                    {/* Search and Add Branch */}
                    <MDBRow className="mb-4 d-flex align-items-center">
                        <MDBCol md="6">
                            <MDBInput
                                type="text"
                                placeholder="Search Branches"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </MDBCol>
                        <MDBCol md="6" className="d-flex justify-content-end">
                            <MDBBtn color="primary" onClick={handleAddBranch}>
                                Add New Branch
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>

                    {/* Branches Table */}
                    <MDBTable responsive>
                        <MDBTableHead className="table-header">
                            <tr>
                                <th className="custom-th">#</th>
                                <th className="custom-th">Name</th>
                                <th className="custom-th">Email</th>
                                <th className="custom-th">Address</th>
                                <th className="custom-th">Users</th>
                                <th className="custom-th">Actions</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody className="table-row">
                            {filteredBranches.map((branch, index) => (
                                <tr key={branch._id}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <strong>{branch.name}</strong>
                                        <br />
                                        <small>
                                            {branch.availability.map((slot) => (
                                                <div key={slot._id}>
                                                    {slot.day}: {slot.start} - {slot.end}
                                                </div>
                                            ))}
                                        </small>
                                    </td>
                                    <td>{branch.email}
                                    <small>
                                    {branch.holidayHours.map((slot) => (
                                            <div key={slot._id}>
                                                {slot.day}: {slot.start} - {slot.end}
                                            </div>
                                        ))}
                                    </small>
                                    </td>
                                    <td>{branch.address}</td>
                                    <td>
                                        <strong>{admin.username}</strong>
                                        <br />
                                        <small>Super Admin</small>
                                    </td>
                                    <td>
                                        <MDBBtn color="warning" size="sm">
                                            Manage Users
                                        </MDBBtn>{' '}
                                        <MDBBtn color="danger" size="sm">
                                            Remove
                                        </MDBBtn>
                                    </td>
                                </tr>
                            ))}
                        </MDBTableBody>
                    </MDBTable>
                </MDBContainer>
            </div>
        </>
    );
}

export default ManageBranches;
