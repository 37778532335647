import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ForgotPasswordModal from '../../Modals/ForgotPasswordModal';
import { api } from '../../../services';

function AdminLogin() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const navigate = useNavigate();

    const handleAdminLogin = async () => {
        try {
            const response = await api.post('/admin/login', { email, password }); // Admin-specific endpoint
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user_type', response.data.user_type); // Store user_type in localStorage
            console.log("Admin login successful:", response.data.message);
            navigate('/admin/dashboard'); // Redirect to admin dashboard
        } catch (error) {
            setError(error.response?.data?.message || "An error occurred during login.");
        }
    };

    const handleForgotPasswordClick = () => {
        setShowForgotPassword(true);
    };

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <div style={{ width: '100%', maxWidth: '400px', padding: '20px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                <h2 className="text-center mb-4">Admin Login</h2>
                <Form>
                    <Form.Group controlId="email" className="mb-3">
                        <Form.Control
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="password" className="mb-3">
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>
                    {error && <p className="text-danger">{error}</p>}
                    <Button variant="primary" onClick={handleAdminLogin} className="w-100">
                        Log In
                    </Button>
                </Form>
                <hr />
                <div className="text-center">
                    <span>Forgot Password? </span>
                    <a href="#" onClick={handleForgotPasswordClick} className="fw-bold">
                        Reset it here
                    </a>
                </div>
            </div>

            {/* Forgot Password Modal */}
            <ForgotPasswordModal
                show={showForgotPassword}
                onClose={() => setShowForgotPassword(false)}
            />
        </div>
    );
}

export default AdminLogin;
