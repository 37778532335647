import React, { useState, useEffect } from 'react';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBBtn,
    MDBInput,
    MDBCard,
    MDBCardBody,
    MDBIcon,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
} from 'mdb-react-ui-kit';
import { api } from '../services';

function Items() {
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [apps, setApps] = useState([]);
    const [selectedApp, setSelectedApp] = useState('Select App');
    const [selectedAppId, setSelectedAppId] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [pagination, setPagination] = useState({
        totalItems: 0,
        totalPages: 1,
        currentPage: 1,
    });
    const [selectedDate, setSelectedDate] = useState('');
    const [activeTab, setActiveTab] = useState('All'); // For status filtering
    const [sortOrder, setSortOrder] = useState('recent'); // For sorting


    useEffect(() => {
        fetchApps();
        fetchAllItems();
    }, []);
    useEffect(() => {
        filterItems();
    }, [selectedAppId, activeTab, selectedDate, searchQuery, sortOrder, items]);


    const fetchApps = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get('/apps', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setApps(response.data.apps || []);
        } catch (err) {
            console.error('Error fetching apps:', err.message);
        }
    };

    const filterItems = () => {
        let updatedItems = [...items];

        // Filter by selected app
        if (selectedAppId) {
            updatedItems = updatedItems.filter((item) => item.appId === selectedAppId);
        }

        // Filter by status (if applicable)
        if (activeTab !== 'All') {
            updatedItems = updatedItems.filter((item) => item.status === activeTab);
        }

        // Filter by date
        if (selectedDate) {
            updatedItems = updatedItems.filter(
                (item) =>
                    new Date(item.dateAdded).toISOString().split('T')[0] === selectedDate
            );
        }

        // Search query filter
        if (searchQuery) {
            updatedItems = updatedItems.filter((item) =>
                item.item_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.category.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        // Sort items
        updatedItems.sort((a, b) => {
            const dateA = new Date(a.dateAdded);
            const dateB = new Date(b.dateAdded);
            return sortOrder === 'recent' ? dateB - dateA : dateA - dateB;
        });

        setFilteredItems(updatedItems);
    };

    const fetchAllItems = async (page = 1) => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get(`/catalog/items?page=${page}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const { items: fetchedItems, totalItems, totalPages } = response.data;
            setItems(fetchedItems);
            setFilteredItems(fetchedItems);
            setPagination({ totalItems, totalPages, currentPage: page });
        } catch (err) {
            console.error('Error fetching items:', err.message);
        }
    };

    const fetchItemsByApp = async (appId, page = 1) => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get(`/apps/${appId}/catalog/items?page=${page}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const { items: fetchedItems, totalItems, totalPages } = response.data;
            setItems(fetchedItems);
            setFilteredItems(fetchedItems);
            setPagination({ totalItems, totalPages, currentPage: page });
        } catch (err) {
            console.error('Error fetching items by app:', err.message);
        }
    };

    const handleAppSelect = (app) => {
        setSelectedApp(app.application);
        setSelectedAppId(app._id);
        fetchItemsByApp(app._id, 1);
    };

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);

        if (query === '') {
            setFilteredItems(items);
        } else {
            const filtered = items.filter((item) =>
                item.item_name.toLowerCase().includes(query) ||
                item.description.toLowerCase().includes(query) ||
                item.category.toLowerCase().includes(query)
            );
            setFilteredItems(filtered);
        }
    };

    const handlePageChange = (page) => {
        if (selectedAppId) {
            fetchItemsByApp(selectedAppId, page);
        } else {
            fetchAllItems(page);
        }
    };

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2 className="mb-4" style={{ color: "#333" }}>All Items</h2>

                    {/* App Dropdown and Search */}
                    <MDBRow className="align-items-center mb-4">
                        {/* App Dropdown */}
                        <MDBCol md="2">
                            <MDBDropdown>
                                <MDBDropdownToggle color="light">{selectedApp}</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    {apps.map((app) => (
                                        <MDBDropdownItem
                                            key={app._id}
                                            onClick={() => handleAppSelect(app)}
                                        >
                                            {app.application}
                                        </MDBDropdownItem>
                                    ))}
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>

                        {/* Date Filter */}
                        <MDBCol md="2">
                            <MDBInput
                                type="date"
                                label="Filter by Date"
                                value={selectedDate}
                                onChange={(e) => setSelectedDate(e.target.value)}
                            />
                        </MDBCol>

                        {/* Search Bar */}
                        <MDBCol md="3">
                            <MDBInput
                                type="text"
                                placeholder="Search Items"
                                value={searchQuery}
                                onChange={handleSearch}
                            />
                        </MDBCol>

                        {/* Status Filter */}
                        <MDBCol md="2">
                            <MDBDropdown>
                                <MDBIcon fas icon="filter" className="me-2" />
                                <MDBDropdownToggle color="secondry">Status</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    {['All', 'Available', 'Out of Stock', 'Discontinued'].map((status) => (
                                        <MDBDropdownItem
                                            key={status}
                                            onClick={() => setActiveTab(status)}
                                        >
                                            {status}
                                        </MDBDropdownItem>
                                    ))}
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>

                        {/* Sort Dropdown */}
                        <MDBCol md="2">
                            <MDBDropdown>
                                <MDBDropdownToggle>Sort by</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem onClick={() => setSortOrder('recent')}>
                                        Recent
                                    </MDBDropdownItem>
                                    <MDBDropdownItem onClick={() => setSortOrder('oldest')}>
                                        Oldest
                                    </MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>
                    </MDBRow>


                    {/* Items Table */}
                    <MDBTable align="middle" responsive>
                            <MDBTableHead className="table-header">
                                    <tr>
                                         <th className="custom-th">Name</th>
                                         <th className="custom-th">Description</th>
                                         <th className="custom-th">Category</th>
                                         <th className="custom-th">Price</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {filteredItems.map((item) => (
                                        <tr key={item.item_id}>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <img
                                                        src={item.image_link}
                                                        alt={item.item_name}
                                                        className="rounded me-3"
                                                        style={{ width: "40px", height: "40px" }}
                                                    />
                                                    <div>{item.item_name}</div>
                                                </div>
                                            </td>
                                            <td>{item.description}</td>
                                            <td>{item.category}</td>
                                            <td>SAR {item.price.toFixed(2)}</td>
                                        </tr>
                                    ))}
                                </MDBTableBody>
                            </MDBTable>

                    {/* Pagination */}
                    <MDBRow className="mt-4">
                        <MDBCol>
                            <div className="d-flex justify-content-between align-items-center">
                                <MDBBtn
                                    disabled={pagination.currentPage === 1}
                                    onClick={() => handlePageChange(pagination.currentPage - 1)}
                                >
                                    Previous
                                </MDBBtn>
                                <span>
                                    Page {pagination.currentPage} of {pagination.totalPages}
                                </span>
                                <MDBBtn
                                    disabled={pagination.currentPage === pagination.totalPages}
                                    onClick={() => handlePageChange(pagination.currentPage + 1)}
                                >
                                    Next
                                </MDBBtn>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        </>
    );
}

export default Items;
