import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardText, MDBBtn } from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';
import TopNavbar from '../../TopNavbar';
import SideNavbar from '../../SideNavbar';
import { api } from '../../../services';

function AdminAppManagement() {
    const [availableApps, setAvailableApps] = useState([]); // Apps managed by admin
    const navigate = useNavigate();

    useEffect(() => {
        fetchAvailableApps();
    }, []);

    // Fetch all available apps
    const fetchAvailableApps = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get('/admin/available-apps', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setAvailableApps(response.data.apps || []);
        } catch (error) {
            console.error('Error fetching available apps:', error.message);
        }
    };

    // Navigate to Add App page
    const handleAddNewApp = () => {
        navigate('/admin/add-app');
    };

    // Edit an app
    const handleEditApp = (app) => {
        navigate('/admin/edit-app', { state: { app } });
    };

    // Delete an app
    const handleDeleteApp = async (appId) => {
        try {
            const token = localStorage.getItem('token');
            await api.delete(`/admin/available-apps/${appId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            fetchAvailableApps(); // Refresh apps after deletion
        } catch (error) {
            console.error('Error deleting app:', error.message);
        }
    };

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2 className="mb-4">Admin - Manage Apps</h2>

                    {/* Add New App Button */}
                    <div className="d-flex justify-content-end mb-4">
                        <MDBBtn color="primary" onClick={handleAddNewApp}>
                            Add New App
                        </MDBBtn>
                    </div>

                    {/* Available Apps Section */}
                    <MDBRow>
                        {availableApps.map((app) => (
                            <MDBCol className="mb-4" md="6" key={app._id}>
                                <MDBCard className="h-100">
                                    <MDBCardBody>
                                        <MDBCardText>
                                            <strong>{app.name}</strong>
                                        </MDBCardText>
                                        <p>{app.description}</p>
                                        <p>
                                            <strong>Status:</strong> {app.status}
                                        </p>
                                        <div className="d-flex justify-content-between">
                                            <MDBBtn
                                                color="info"
                                                outline
                                                size="sm"
                                                onClick={() => handleEditApp(app)}
                                            >
                                                Edit
                                            </MDBBtn>
                                            <MDBBtn
                                                color="danger"
                                                outline
                                                size="sm"
                                                onClick={() => handleDeleteApp(app._id)}
                                            >
                                                Delete
                                            </MDBBtn>
                                        </div>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        ))}
                    </MDBRow>
                </MDBContainer>
            </div>
        </>
    );
}

export default AdminAppManagement;
