import React, { useState } from 'react';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBRow,
    MDBCol,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBBadge,
    MDBRadio
    // Other imports if needed
} from 'mdb-react-ui-kit';

function Branches() {
    const [openModal, setOpenModal] = useState(false);

    const toggleModal = () => {
        setOpenModal(!openModal);
    };

    const branchesData = [
        // Replace with API data
        { id: 1, name: 'Branch 1', location: 'Location 1', manager: 'Manager 1', status: 'Active' },
        { id: 2, name: 'Branch 2', location: 'Location 2', manager: 'Manager 2', status: 'Inactive' },
        // ... more branches
    ];

    return (
        <>
            <TopNavbar />
            <div className="d-flexContent">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area">
                    <h2>Branches</h2>
                    <MDBRow className='mb-4 g-4'>
                        <MDBCol md='12'>
                            <MDBCard className='h-100'>
                                <MDBCardBody className="text-center">
                                    {branchesData.length === 0 && <p>No Branches Added</p>}
                                    <MDBBtn onClick={toggleModal}>Add a branch</MDBBtn>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow>
                        <MDBCol md='12'>
                            <MDBCard className='content-card'>
                                <MDBCardBody>
                                    <MDBTable align='middle' responsive>
                                        <MDBTableHead className="table-header">
                                            <tr>
                                                <th scope='col'>#</th>
                                                <th scope='col'>Name</th>
                                                <th scope='col'>Location</th>
                                                <th scope='col'>Manager</th>
                                                <th scope='col'>Status</th>
                                                <th scope='col'>Actions</th>
                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody className="table-row">
                                            {branchesData.map((branch) => (
                                                <tr key={branch.id}>
                                                    <td>{branch.id}</td>
                                                    <td>{branch.name}</td>
                                                    <td>{branch.location}</td>
                                                    <td>{branch.manager}</td>
                                                    <td>
                                                        <MDBBadge color={branch.status === 'Active' ? 'success' : 'danger'} pill>
                                                            {branch.status}
                                                        </MDBBadge>
                                                    </td>
                                                    <td>
                                                        <MDBBtn color='info' size='sm'>Edit</MDBBtn>
                                                    </td>
                                                </tr>
                                            ))}
                                        </MDBTableBody>
                                    </MDBTable>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>

            {/* Modal for adding new branch */}
            <MDBModal open={openModal} setOpen={setOpenModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Add Branch</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBInput className='mb-4' type='text' label='Name' />
                            <MDBInput className='mb-4' type='text' label='Location' />
                            <MDBInput className='mb-4' type='text' label='Manager' />
                            <div className='mb-4'>
                                <label>Is the branch active?</label>
                                <MDBRadio name='isActive' id='active' label='Yes' />
                                <MDBRadio name='isActive' id='inactive' label='No' />
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleModal}>Close</MDBBtn>
                            <MDBBtn>Submit</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}

export default Branches;
