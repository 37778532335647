import React, { useState, useEffect } from 'react';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBIcon,
    MDBBtn,
    MDBInput,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
} from 'mdb-react-ui-kit';
import { api } from '../services';

function InventoryPage() {
    const [inventory, setInventory] = useState([]);
    const [filteredInventory, setFilteredInventory] = useState([]);
    const [store, setStore] = useState(null); // For storing the fetched store
    const [branches, setBranches] = useState([]); // For storing branch data
    const [selectedBranch, setSelectedBranch] = useState('Select Branch'); // Selected branch name
    const [selectedBranchId, setSelectedBranchId] = useState(null); // Selected branch ID
    const [searchQuery, setSearchQuery] = useState('');
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [pagination, setPagination] = useState({
        totalInventory: 0,
        totalPages: 1,
        currentPage: 1,
    });
    const [editModal, setEditModal] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [selectedDate, setSelectedDate] = useState('');
    const [activeTab, setActiveTab] = useState('All'); // For filtering by status
    const [sortOrder, setSortOrder] = useState('recent'); // For sorting inventory


    useEffect(() => {
        fetchStore(); // Fetch the store first
    }, []);

    useEffect(() => {
        if (store && store._id) {
            fetchBranches(store._id); // Fetch branches when the store is fetched
            fetchAllInventory(); // Fetch all inventory
        }
    }, [store]);

    useEffect(() => {
        filterInventory();
    }, [selectedBranchId, activeTab, selectedDate, searchQuery, sortOrder, inventory]);
    

    const filterInventory = () => {
        let updatedInventory = [...inventory];
    
        // Filter by branch
        if (selectedBranchId) {
            updatedInventory = updatedInventory.filter((item) => item.branchId === selectedBranchId);
        }
    
        // Filter by status (e.g., 'All', 'In Stock', 'Out of Stock')
        if (activeTab !== 'All') {
            updatedInventory = updatedInventory.filter((item) => item.status === activeTab);
        }
    
        // Filter by date
        if (selectedDate) {
            updatedInventory = updatedInventory.filter(
                (item) => new Date(item.dateAdded).toISOString().split('T')[0] === selectedDate
            );
        }
    
        // Search filter
        if (searchQuery) {
            updatedInventory = updatedInventory.filter((item) =>
                item.product_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.supplier.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }
    
        // Sort the inventory
        updatedInventory.sort((a, b) => {
            const dateA = new Date(a.dateAdded);
            const dateB = new Date(b.dateAdded);
            return sortOrder === 'recent' ? dateB - dateA : dateA - dateB;
        });
    
        setFilteredInventory(updatedInventory);
    };
    
    const fetchStore = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get('/stores/my', {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (response.data && response.data.stores && response.data.stores.length > 0) {
                setStore(response.data.stores[0]); // Assume the first store is the active one
            } else {
                console.error('No store found');
                alert('No store is associated with your account.');
            }
        } catch (err) {
            console.error('Error fetching store:', err.message);
            alert('Failed to fetch store. Please try again later.');
        }
    };

    const fetchBranches = async (storeId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get(`/stores/${storeId}/branches`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (response.data.branches && response.data.branches.length > 0) {
                setBranches(response.data.branches);
            } else {
                console.error('No branches found');
                alert('No branches are associated with the store.');
            }
        } catch (err) {
            console.error('Error fetching branches:', err.message);
            alert('Failed to fetch branches. Please try again later.');
        }
    };

    const fetchAllInventory = async (page = 1) => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get(`/inventory?page=${page}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const { inventory: fetchedInventory, totalInventory, totalPages } = response.data;
            setInventory(fetchedInventory);
            setFilteredInventory(fetchedInventory);
            setTotalQuantity(fetchedInventory.reduce((sum, item) => sum + item.quantity, 0));
            setPagination({ totalInventory, totalPages, currentPage: page });
        } catch (err) {
            console.error('Error fetching inventory:', err.message);
        }
    };

    const fetchInventoryByBranch = async (branchId, page = 1) => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get(`/branches/${branchId}/inventory?page=${page}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const { inventory: fetchedInventory, totalPages } = response.data;
            setInventory(fetchedInventory);
            setFilteredInventory(fetchedInventory);
            setTotalQuantity(fetchedInventory.reduce((sum, item) => sum + item.quantity, 0));
            setPagination((prev) => ({
                ...prev,
                currentPage: page,
                totalPages,
            }));
        } catch (err) {
            console.error('Error fetching inventory by branch:', err.message);
            alert('Failed to fetch inventory. Please try again later.');
        }
    };

    const handleBranchSelect = (branch) => {
        setSelectedBranch(branch.name);
        setSelectedBranchId(branch._id);
        fetchInventoryByBranch(branch._id);
    };

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);

        if (query === '') {
            setFilteredInventory(inventory);
        } else {
            const filtered = inventory.filter((item) =>
                item.product_name.toLowerCase().includes(query) ||
                item.category.toLowerCase().includes(query) ||
                item.supplier.toLowerCase().includes(query)
            );
            setFilteredInventory(filtered);
        }
    };

    const toggleEditModal = (item = null) => {
        setEditItem(item);
        setEditModal(!editModal);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditItem((prev) => ({ ...prev, [name]: value }));
    };

    const handleUpdate = async () => {
        try {
            const token = localStorage.getItem('token');
            await api.put(`/inventory/${editItem._id}`, editItem, {
                headers: { Authorization: `Bearer ${token}` },
            });
            toggleEditModal();
            fetchInventoryByBranch(selectedBranchId, pagination.currentPage);
        } catch (err) {
            console.error('Error updating inventory item:', err.message);
            alert('Failed to update the item. Please try again.');
        }
    };

    const handleDelete = async (id) => {
        if (!window.confirm('Are you sure you want to delete this item?')) return;
        try {
            const token = localStorage.getItem('token');
            await api.delete(`/inventory/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            fetchInventoryByBranch(selectedBranchId, pagination.currentPage);
        } catch (err) {
            console.error('Error deleting inventory item:', err.message);
            alert('Failed to delete the item. Please try again.');
        }
    };

    const handlePageChange = (page) => {
        fetchInventoryByBranch(selectedBranchId, page);
    };

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2 className="mb-4" style={{ color: "#333" }}>Inventory</h2>

                    {/* Statistics Section */}
                    <MDBRow className="mb-4 g-4">
                        <MDBCol md="4">
                            <MDBCard className="stat-card h-100 bg-light">
                                <MDBCardBody>
                                    <h6>Total Items</h6>
                                    <h3>{inventory.length}</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="4">
                            <MDBCard className="stat-card h-100 bg-light">
                                <MDBCardBody>
                                    <h6>Total Quantity</h6>
                                    <h3>{totalQuantity}</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="align-items-center mb-4">
                        {/* Branch Selector */}
                        <MDBCol md="2">
                            <MDBDropdown>
                                <MDBDropdownToggle color="light">{selectedBranch}</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    {branches.map((branch) => (
                                        <MDBDropdownItem
                                            key={branch._id}
                                            onClick={() => handleBranchSelect(branch)}
                                        >
                                            {branch.name}
                                        </MDBDropdownItem>
                                    ))}
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>

                        {/* Date Filter */}
                        <MDBCol md="2">
                            <MDBInput
                                type="date"
                                label="Filter by Date"
                                value={selectedDate}
                                onChange={(e) => setSelectedDate(e.target.value)}
                            />
                        </MDBCol>

                        {/* Search Bar */}
                        <MDBCol md="3">
                            <MDBInput
                                type="text"
                                placeholder="Search inventory"
                                value={searchQuery}
                                onChange={handleSearch}
                            />
                        </MDBCol>

                        {/* Filter by Status */}
                        <MDBCol md="2">
                            <MDBDropdown>
                                <MDBIcon fas icon="filter" className="me-2" />
                                <MDBDropdownToggle color="secondry">Status</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    {['All', 'In Stock', 'Out of Stock', 'Low Stock'].map((status) => (
                                        <MDBDropdownItem
                                            key={status}
                                            onClick={() => setActiveTab(status)}
                                        >
                                            {status}
                                        </MDBDropdownItem>
                                    ))}
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>

                        {/* Sort Dropdown */}
                        <MDBCol md="2">
                            <MDBDropdown>
                                <MDBDropdownToggle>Sort by</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem onClick={() => setSortOrder('recent')}>
                                        Recent
                                    </MDBDropdownItem>
                                    <MDBDropdownItem onClick={() => setSortOrder('oldest')}>
                                        Oldest
                                    </MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>
                    </MDBRow>


                    <MDBTable responsive>
                        <MDBTableHead className="table-header">
                            <tr>
                                 <th className="custom-th">Product Name</th>
                                 <th className="custom-th">Category</th>
                                 <th className="custom-th">Quantity</th>
                                 <th className="custom-th">Price</th>
                                 <th className="custom-th">Supplier</th>
                                 <th className="custom-th">Actions</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {filteredInventory.map((item) => (
                                <tr key={item._id}>
                                    <td>{item.product_name}</td>
                                    <td>{item.category}</td>
                                    <td>{item.quantity}</td>
                                    <td>${item.price.toFixed(2)}</td>
                                    <td>{item.supplier}</td>
                                    <td>
                                                <MDBBtn
                                                    color="secondary"
                                                    outline
                                                    size="sm"
                                                    style={{ marginRight: "8px" }}
                                                    onClick={() => toggleEditModal(item)}
                                                >
                                                    Edit
                                                </MDBBtn>
                                                <MDBBtn 
                                                color="danger"
                                                outline
                                                size="sm"
                                                onClick={() => handleDelete(item._id)}
                                                >
                                                    Delete
                                                </MDBBtn>
                                            </td>
                                </tr>
                            ))}
                        </MDBTableBody>
                    </MDBTable>


                    <MDBRow className="mt-4">
                        <MDBCol>
                            <div className="d-flex justify-content-between align-items-center">
                                <MDBBtn
                                    disabled={pagination.currentPage === 1}
                                    onClick={() => handlePageChange(pagination.currentPage - 1)}
                                >
                                    Previous
                                </MDBBtn>
                                <span>
                                    Page {pagination.currentPage} of {pagination.totalPages}
                                </span>
                                <MDBBtn
                                    disabled={pagination.currentPage === pagination.totalPages}
                                    onClick={() => handlePageChange(pagination.currentPage + 1)}
                                >
                                    Next
                                </MDBBtn>
                            </div>
                        </MDBCol>
                    </MDBRow>

                    {editItem && (
                        <div className={`modal ${editModal ? "show d-block" : ''}`} tabIndex="-1">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5>Edit Inventory Item</h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={toggleEditModal}
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <MDBInput
                                            label="Product Name"
                                            name="product_name"
                                            value={editItem.product_name || ''}
                                            onChange={handleEditChange}
                                            className="mb-3"
                                        />
                                        <MDBInput
                                            label="Category"
                                            name="category"
                                            value={editItem.category || ''}
                                            onChange={handleEditChange}
                                            className="mb-3"
                                        />
                                        <MDBInput
                                            label="Quantity"
                                            name="quantity"
                                            type="number"
                                            value={editItem.quantity || ''}
                                            onChange={handleEditChange}
                                            className="mb-3"
                                        />
                                        <MDBInput
                                            label="Price"
                                            name="price"
                                            type="number"
                                            value={editItem.price || ''}
                                            onChange={handleEditChange}
                                            className="mb-3"
                                        />
                                        <MDBInput
                                            label="Supplier"
                                            name="supplier"
                                            value={editItem.supplier || ''}
                                            onChange={handleEditChange}
                                            className="mb-3"
                                        />
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={toggleEditModal}
                                        >
                                            Close
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={handleUpdate}
                                        >
                                            Save Changes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </MDBContainer>
            </div>
        </>
    );
}

export default InventoryPage;
