import React, { useState } from 'react';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBInput,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBCheckbox,
} from 'mdb-react-ui-kit';

function EditCatalog() {
    const [selectedBranch, setSelectedBranch] = useState('Select Branch');
    const [selectedApp, setSelectedApp] = useState('Select Apps');
    const [searchQuery, setSearchQuery] = useState('');

    const itemsData = [
        { id: 1, name: "Pizza Small", description: "A pizza which is small in size which serves 1", category: "Appetisers", price: "SAR 12.00" },
        { id: 2, name: "Pizza Medium", description: "A pizza which is medium in size which serves 1", category: "Appetisers", price: "SAR 12.00" },
        { id: 3, name: "Pizza Large", description: "A pizza which is large in size which serves 1", category: "Appetisers", price: "SAR 12.00" },
        { id: 4, name: "Chicken Biryani", description: "A delicious Chicken biryani serves 1", category: "Main Course", price: "SAR 12.00" },
        { id: 5, name: "Mutton Biryani", description: "A delicious Mutton biryani serves 2", category: "Main Course", price: "SAR 12.00" },
        { id: 6, name: "Pepsi 500 ml", description: "A cool Pepsi to make chill which serves 2", category: "Beverages", price: "SAR 12.00" },
    ];

    const filteredItems = itemsData.filter(item =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h4 className="text-muted mb-3">Catalog Overview &gt; <strong>Edit Catalog</strong></h4>
                    <h2 className="mb-4" style={{ color: "#333" }}>Edit Catalog</h2>

                    {/* Catalog Details */}
                    <MDBRow className="mb-3">
                        <MDBCol md="4">
                            <MDBInput label="Catalog Name" value="Catalog 1" disabled />
                        </MDBCol>
                        <MDBCol md="4">
                            <MDBDropdown>
                                <MDBDropdownToggle color="primary" style={{ width: "100%" }}>{selectedBranch}</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link onClick={() => setSelectedBranch('Branch 1')}>Branch 1</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={() => setSelectedBranch('Branch 2')}>Branch 2</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={() => setSelectedBranch('Branch 3')}>Branch 3</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>
                        <MDBCol md="4">
                            <MDBDropdown>
                                <MDBDropdownToggle color="primary" style={{ width: "100%" }}>{selectedApp}</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link onClick={() => setSelectedApp('Uber Eats')}>Uber Eats</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={() => setSelectedApp('Door Dash')}>Door Dash</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={() => setSelectedApp('Deliveroo')}>Deliveroo</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>
                    </MDBRow>

                    {/* Search Bar */}
                    <MDBRow className="mb-3">
                        <MDBCol>
                            <MDBInput 
                                type="text" 
                                placeholder="Search Item" 
                                value={searchQuery} 
                                onChange={(e) => setSearchQuery(e.target.value)} 
                                style={{ borderRadius: "8px" }}
                            />
                        </MDBCol>
                    </MDBRow>

                    {/* Items Table */}
                    <MDBCard style={{ borderRadius: "10px" }}>
                        <MDBCardBody>
                            <MDBTable align="middle" responsive className="mb-0">
                                <MDBTableHead className="bg-light" style={{ borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}>
                                    <tr>
                                        <th className="custom-th">
                                            <MDBCheckbox />
                                        </th>
                                        <th className="custom-th">Name</th>
                                        <th className="custom-th">Description</th>
                                        <th className="custom-th">Category</th>
                                        <th className="custom-th">Price</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody className="table-row">
                                    {filteredItems.map(item => (
                                        <tr key={item.id}>
                                            <td><MDBCheckbox /></td>
                                            <td>{item.name}</td>
                                            <td>{item.description}</td>
                                            <td>{item.category}</td>
                                            <td>{item.price}</td>
                                        </tr>
                                    ))}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBCardBody>
                    </MDBCard>

                    {/* Action Buttons */}
                    <MDBRow className="mt-4">
                        <MDBCol className="d-flex justify-content-end gap-2">
                            <MDBBtn color="secondary" outline style={{ borderRadius: "8px", padding: "8px 20px" }}>Save as Draft</MDBBtn>
                            <MDBBtn color="danger" style={{ borderRadius: "8px", padding: "8px 20px" }}>Cancel</MDBBtn>
                            <MDBBtn color="success" style={{ borderRadius: "8px", padding: "8px 20px" }}>Save</MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        </>
    );
}

export default EditCatalog;
