import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBTable,
    MDBTableBody,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBInput,
    MDBTableHead,
    MDBBtn,
} from 'mdb-react-ui-kit';
import { api } from '../services';

function CatalogOverview() {
    const [activeTab, setActiveTab] = useState('All');
    const [viewMode, setViewMode] = useState('list');
    const [catalogs, setCatalogs] = useState([]);
    const [filteredCatalogs, setFilteredCatalogs] = useState([]);
    const [apps, setApps] = useState([]);
    const [selectedApp, setSelectedApp] = useState('Select App');
    const [searchQuery, setSearchQuery] = useState('');
    const [stats, setStats] = useState({
        totalCatalogs: 0,
    });

    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState('');
    const [sortOrder, setSortOrder] = useState('recent'); // Sorting order


    useEffect(() => {
        fetchApps(); // Fetch apps on component mount
        fetchCatalogs(); // Fetch all catalogs initially
    }, []);
    useEffect(() => {
        filterCatalogs();
    }, [searchQuery, selectedDate, sortOrder, catalogs]);


    const fetchApps = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get('/apps', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setApps(response.data.apps || []);
        } catch (err) {
            console.error('Error fetching apps:', err.message);
        }
    };

    const filterCatalogs = () => {
        let updatedCatalogs = [...catalogs];

        // Filter by search query
        if (searchQuery) {
            updatedCatalogs = updatedCatalogs.filter((catalog) =>
                catalog.brand?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                catalog.description?.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        // Filter by selected date
        if (selectedDate) {
            updatedCatalogs = updatedCatalogs.filter(
                (catalog) =>
                    new Date(catalog.dateAdded).toISOString().split('T')[0] === selectedDate
            );
        }

        // Sort catalogs
        updatedCatalogs.sort((a, b) => {
            const dateA = new Date(a.dateAdded);
            const dateB = new Date(b.dateAdded);
            return sortOrder === 'recent' ? dateB - dateA : dateA - dateB;
        });

        setFilteredCatalogs(updatedCatalogs);
    };


    const fetchCatalogs = async (appId = null) => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get(appId ? `/apps/${appId}/catalogs` : '/catalogs', {
                headers: { Authorization: `Bearer ${token}` },
            });

            const { catalogs } = response.data || { catalogs: [] };
            setCatalogs(catalogs);
            setFilteredCatalogs(catalogs); // Initialize filtered catalogs
            calculateStats(catalogs);
        } catch (err) {
            console.error('Error fetching catalogs:', err.message);
        }
    };

    const calculateStats = (catalogs = []) => {
        setStats({ totalCatalogs: catalogs.length });
    };

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);

        if (query === '') {
            setFilteredCatalogs(catalogs);
        } else {
            const filtered = catalogs.filter((catalog) =>
                catalog.brand?.toLowerCase().includes(query) ||
                catalog.description?.toLowerCase().includes(query)
            );
            setFilteredCatalogs(filtered);
        }
    };

    const handleTabClick = (tab) => setActiveTab(tab);

    const handleAppSelect = (app) => {
        setSelectedApp(app.application);
        fetchCatalogs(app._id); // Fetch catalogs for the selected app
    };

    const handleViewModeChange = (mode) => setViewMode(mode);

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2 className="mb-4">Catalog Overview</h2>

                    {/* Statistics Section */}
                    <MDBRow className="mb-4 g-4">
                        <MDBCol md="6">
                            <MDBCard className="stat-card h-100">
                                <MDBCardBody>
                                    <h6>Total Catalogs</h6>
                                    <h3 className="mb-0">{stats.totalCatalogs}</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="align-items-center mb-4">
                        {/* App Dropdown */}
                        <MDBCol md="2">
                            <MDBDropdown>
                                <MDBDropdownToggle color="primary">{selectedApp}</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    {apps.map((app) => (
                                        <MDBDropdownItem
                                            key={app._id}
                                            onClick={() => handleAppSelect(app)}
                                        >
                                            {app.application}
                                        </MDBDropdownItem>
                                    ))}
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>

                        {/* Date Filter */}
                        <MDBCol md="2">
                            <MDBInput
                                type="date"
                                label="Filter by Date"
                                value={selectedDate}
                                onChange={(e) => setSelectedDate(e.target.value)}
                            />
                        </MDBCol>

                        {/* Search Bar */}
                        <MDBCol md="3">
                            <MDBInput
                                type="text"
                                placeholder="Search Catalogs"
                                value={searchQuery}
                                onChange={handleSearch}
                            />
                        </MDBCol>

                        {/* Sort Dropdown */}
                        <MDBCol md="2">
                            <MDBDropdown>
                                <MDBDropdownToggle>Sort by</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem onClick={() => setSortOrder('recent')}>
                                        Recent
                                    </MDBDropdownItem>
                                    <MDBDropdownItem onClick={() => setSortOrder('oldest')}>
                                        Oldest
                                    </MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>

                        {/* View Mode Toggle */}
                        <MDBCol md="3" className="text-end">
                            <MDBBtn
                                color="light"
                                onClick={() => setViewMode('list')}
                                active={viewMode === 'list'}
                            >
                                List
                            </MDBBtn>
                            <MDBBtn
                                color="light"
                                onClick={() => setViewMode('grid')}
                                active={viewMode === 'grid'}
                            >
                                Grid
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>


                    {viewMode === 'grid' ? (
                        <MDBRow>
                            {filteredCatalogs?.map((catalog) => (
                                <MDBCol md="4" key={catalog._id}>
                                    <MDBCard className="order-card h-100">
                                        <MDBCardBody className="position-relative">
                                            <div>
                                                <h5 className="mb-1">{catalog.brand}</h5>
                                                <small className="text-muted">{catalog.description}</small>
                                            </div>
                                            <div className="text-center my-3">
                                                <h6>{catalog.charges} {catalog.currency}</h6>
                                                <small className="text-muted">{catalog.location}</small>
                                            </div>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            ))}
                        </MDBRow>
                    ) : (
                        <MDBTable responsive>
                            <MDBTableHead className="table-header">
                                <tr>
                                    <th className="custom-th">#</th>
                                    <th className="custom-th">Catalog Name</th>
                                    <th className="custom-th">Description</th>
                                    <th className="custom-th">App</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {filteredCatalogs?.map((catalog, index) => (
                                    <tr key={catalog._id}>
                                        <td>{index + 1}</td>
                                        <td>{catalog.brand}</td>
                                        <td>{catalog.description}</td>
                                        <td>
                                            {apps.find((app) => app._id === catalog.appId)?.application || 'N/A'}
                                        </td>
                                    </tr>
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                    )}

                </MDBContainer>
            </div>
        </>
    );
}

export default CatalogOverview;
