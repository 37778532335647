import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { api } from '../services';
import logo from '../assets/logos/logo.png';
import ForgotPasswordModal from './Modals/ForgotPasswordModal';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            const response = await api.post('/login', { email, password });

            // Handle account status messages
            if (response.data.message === 'Your account is under review. We will contact you soon.') {
                setError('Your account is under review. We will contact you soon.');
                return;
            }
            if (response.data.message === 'Your account is inactive. Please contact support@loops.sa.') {
                setError('Your account is inactive. Please contact support@loops.sa.');
                return;
            }

            // Save token and user type in localStorage
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user_type', response.data.user_type);

            console.log('Login successful:', response.data.message);
            navigate('/dashboard'); // Navigate to the dashboard
        } catch (error) {
            setError(error.response?.data?.message || 'An error occurred during login.');
        }
    };

    const handleForgotPasswordClick = () => {
        setShowForgotPassword(true);
    };

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <div
                style={{
                    width: '100%',
                    maxWidth: '400px',
                    padding: '20px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                }}
            >
                {/* Logo Section */}
                <div className="text-center mb-4">
                <img src={logo} alt="logo" style={{ maxWidth: "150px", width: "100%" }} />
                </div>

                {/* Login Form */}
                <h2 className="text-center mb-4">Login</h2>
                <Form>
                    <Form.Group controlId="email" className="mb-3">
                        <Form.Control
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="password" className="mb-3">
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>
                    {error && <p className="text-danger">{error}</p>}
                    <Button variant="primary" onClick={handleLogin} className="w-100">
                        Log In
                    </Button>
                </Form>
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                    <a
                        href="#"
                        className="small text-muted"
                        onClick={(e) => {
                            e.preventDefault();
                            handleForgotPasswordClick();
                        }}
                    >
                        Forgot Password?
                    </a>
                    <a href="/register" className="fw-bold">Create Account</a>
                </div>
            </div>

            {/* Forgot Password Modal */}
            <ForgotPasswordModal
                show={showForgotPassword}
                onClose={() => setShowForgotPassword(false)}
            />
        </div>
    );
}

export default LoginPage;
