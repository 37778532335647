import React, { useState } from 'react';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBBtn,
    MDBInput,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBIcon,
} from 'mdb-react-ui-kit';

function ReportsPage() {
    const [activeTab, setActiveTab] = useState('sales');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedFilter, setSelectedFilter] = useState('All');
    const [sortOrder, setSortOrder] = useState('Relevance');

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const reportData = [
        { id: 1, type: "Daily Report", app: "Uber Eats", branch: "Branch 1", business: "Business 1", date: "23 Sep 2023", amount: "$500" },
        { id: 2, type: "Weekly Report", app: "Door Dash", branch: "Branch 2", business: "Business 2", date: "16 Sep 2023", amount: "$1500" },
        { id: 3, type: "Monthly Report", app: "Deliveroo", branch: "Branch 3", business: "Business 3", date: "01 Sep 2023", amount: "$4500" },
    ];

    const filteredReports = reportData.filter(report =>
        report.type.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h4 className="text-muted mb-3">Reports</h4>

                    {/* Tabs for Sales and Orders Report */}
                    <MDBTabs fill className="mb-4">
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => toggleTab('sales')} active={activeTab === 'sales'}>
                                Sales Report
                            </MDBTabsLink>
                        </MDBTabsItem>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => toggleTab('orders')} active={activeTab === 'orders'}>
                                Orders Report
                            </MDBTabsLink>
                        </MDBTabsItem>
                    </MDBTabs>

                    {/* Statistics Section */}
                    <MDBRow className="mb-4 g-4">
                        <MDBCol md="3">
                            <MDBCard className="stat-card h-100 bg-light" style={{ borderRadius: "10px" }}>
                                <MDBCardBody>
                                    <h6 className="text-muted mb-1">{activeTab === 'sales' ? 'Total Sales' : 'Total Orders'}</h6>
                                    <h3 className="mb-0">{activeTab === 'sales' ? '$500k' : '105'}</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBCard className="stat-card h-100 bg-light" style={{ borderRadius: "10px" }}>
                                <MDBCardBody>
                                    <h6 className="text-muted mb-1">{activeTab === 'sales' ? 'Branches Sales' : 'Completed Orders'}</h6>
                                    <h3 className="mb-0">{activeTab === 'sales' ? '$120k' : '100'}</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBCard className="stat-card h-100 bg-light" style={{ borderRadius: "10px" }}>
                                <MDBCardBody>
                                    <h6 className="text-muted mb-1">App Sales</h6>
                                    <h3 className="mb-0">$380k</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBCard className="stat-card h-100 bg-light" style={{ borderRadius: "10px" }}>
                                <MDBCardBody>
                                    <h6 className="text-muted mb-1">Total Commission</h6>
                                    <h3 className="mb-0">$380k</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>

                    {/* Search, Filter, Sort, and Generate Report */}
                    <MDBRow className="mb-4 d-flex align-items-center justify-content-between">
                        {/* Search Bar */}
                        <MDBCol md="6" className="d-flex">
                            <MDBInput
                                type="text"
                                placeholder="Search Report"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                style={{ borderRadius: "8px", flex: 1 }}
                            />
                        </MDBCol>

                        {/* Filter Dropdown */}
                        <MDBCol md="2" className="d-flex align-items-center justify-content-start">
                            <MDBIcon fas icon="filter" className="me-2" />
                            <MDBDropdown>
                                <MDBDropdownToggle color="secondry" style={{ borderRadius: "8px" }}>
                                    Filter By
                                </MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link onClick={() => setSelectedFilter('All')}>All</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={() => setSelectedFilter('Daily Report')}>Daily Report</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={() => setSelectedFilter('Weekly Report')}>Weekly Report</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={() => setSelectedFilter('Monthly Report')}>Monthly Report</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>

                        {/* Sort Dropdown */}
                        <MDBCol md="2" className="d-flex justify-content-start align-items-center">
                            <span className="me-2">Sort By:</span>
                            <MDBDropdown>
                                <MDBDropdownToggle color="light" style={{ borderRadius: "8px" }}>
                                    {sortOrder}
                                </MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link onClick={() => setSortOrder('Relevance')}>Relevance</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={() => setSortOrder('Amount')}>Amount</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={() => setSortOrder('Date')}>Date</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>

                        {/* Generate Report Button */}
                        <MDBCol md="2" className="d-flex justify-content-end">
                            <MDBBtn color="dark" style={{ borderRadius: "8px" }}>Generate Report</MDBBtn>
                        </MDBCol>
                    </MDBRow>

                    {/* Reports Table */}
                    <MDBCard style={{ borderRadius: "10px" }}>
                        <MDBCardBody>
                            <MDBTable align="middle" responsive className="mb-0">
                                <MDBTableHead className="bg-light">
                                    <tr>
                                        <th className="custom-th">Type</th>
                                        <th className="custom-th">App</th>
                                        <th className="custom-th">Branch</th>
                                        <th className="custom-th">Business</th>
                                        <th className="custom-th">Date</th>
                                        <th className="custom-th">Amount</th>
                                        <th className="custom-th">Actions</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody className="table-row">
                                    {filteredReports.map(report => (
                                        <tr key={report.id}>
                                            <td>{report.type}</td>
                                            <td>{report.app}</td>
                                            <td>{report.branch}</td>
                                            <td>{report.business}</td>
                                            <td>{report.date}</td>
                                            <td>{report.amount}</td>
                                            <td>
                                                <MDBBtn color="secondary" outline size="sm">View Details</MDBBtn>
                                            </td>
                                        </tr>
                                    ))}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </div>
        </>
    );
}

export default ReportsPage;
