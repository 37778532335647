import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBInput,
    MDBBtn,
    MDBIcon,
} from 'mdb-react-ui-kit';
import { api } from '../services';

function EditStore() {
    const navigate = useNavigate();
    const location = useLocation();
    const { storeId } = location.state || {};

    const [storeData, setStoreData] = useState({
        name: '',
        email: '',
    });
    const [branches, setBranches] = useState([]);
    const [newBranch, setNewBranch] = useState({
        name: '',
        email: '',
        address: '',
        availability: [],
        breakHours: [],
        holidayHours: [],
    });
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        if (storeId) fetchStoreDetails();
    }, [storeId]);

    const fetchStoreDetails = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get(`/stores/${storeId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setStoreData({ name: response.data.name, email: response.data.email });
            setBranches(response.data.branches || []);
        } catch (error) {
            console.error('Error fetching store details:', error.response?.data || error.message);
            setError('Failed to load store details.');
        }
    };

    const handleStoreUpdate = async () => {
        try {
            const token = localStorage.getItem('token');
            await api.put(
                `/stores/${storeId}`,
                storeData,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            setSuccessMessage('Store updated successfully!');
            setTimeout(() => setSuccessMessage(''), 3000);
        } catch (err) {
            console.error('Error updating store:', err.response?.data || err.message);
            setError('Failed to update store.');
        }
    };

    const handleBranchUpdate = async (branchId, updatedBranch) => {
        try {
            const token = localStorage.getItem('token');
            await api.put(
                `/stores/${storeId}/branches/${branchId}`,
                updatedBranch,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            setSuccessMessage('Branch updated successfully!');
            fetchStoreDetails(); // Refresh branches
        } catch (err) {
            console.error('Error updating branch:', err.response?.data || err.message);
            setError('Failed to update branch.');
        }
    };

    const handleBranchDelete = async (branchId) => {
        try {
            const token = localStorage.getItem('token');
            await api.delete(`/stores/${storeId}/branches/${branchId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            fetchStoreDetails(); // Refresh branches
            setSuccessMessage('Branch deleted successfully!');
        } catch (err) {
            console.error('Error deleting branch:', err.response?.data || err.message);
            setError('Failed to delete branch.');
        }
    };

    const handleNewBranchAdd = async () => {
        if (!newBranch.name || !newBranch.address || newBranch.availability.length === 0) {
            setError('Branch name, address, and availability are required.');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            await api.post(
                `/stores/${storeId}/branches`,
                newBranch,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            setNewBranch({
                name: '',
                email: '',
                address: '',
                availability: [],
                breakHours: [],
                holidayHours: [],
            });
            fetchStoreDetails(); // Refresh branches
            setSuccessMessage('Branch added successfully!');
        } catch (err) {
            console.error('Error adding branch:', err.response?.data || err.message);
            setError('Failed to add branch.');
        }
    };

    const handleFieldChange = (setter, field, value) => {
        setter((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleArrayFieldChange = (setter, index, field, value) => {
        setter((prev) => {
            const updated = [...prev];
            updated[index][field] = value;
            return updated;
        });
    };

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2 className="mb-4">Edit Store</h2>
                    <MDBCard>
                        <MDBCardBody>
                            <h5>Store Details</h5>
                            <MDBRow>
                                <MDBCol md="6">
                                    <MDBInput
                                        label="Store Name"
                                        type="text"
                                        value={storeData.name}
                                        onChange={(e) =>
                                            handleFieldChange(setStoreData, 'name', e.target.value)
                                        }
                                    />
                                </MDBCol>
                                <MDBCol md="6">
                                    <MDBInput
                                        label="Email"
                                        type="email"
                                        value={storeData.email}
                                        onChange={(e) =>
                                            handleFieldChange(setStoreData, 'email', e.target.value)
                                        }
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBBtn color="primary" className="mt-3" onClick={handleStoreUpdate}>
                                Save Store
                            </MDBBtn>
                        </MDBCardBody>
                    </MDBCard>

                    <h5 className="mt-4">Branches</h5>
                    {branches.map((branch, branchIndex) => (
                        <MDBCard className="mb-4" key={branch._id}>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol md="6">
                                        <MDBInput
                                            label="Branch Name"
                                            type="text"
                                            value={branch.name}
                                            onChange={(e) =>
                                                handleArrayFieldChange(
                                                    setBranches,
                                                    branchIndex,
                                                    'name',
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </MDBCol>
                                    <MDBCol md="6">
                                        <MDBInput
                                            label="Email"
                                            type="email"
                                            value={branch.email}
                                            onChange={(e) =>
                                                handleArrayFieldChange(
                                                    setBranches,
                                                    branchIndex,
                                                    'email',
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md="12">
                                        <MDBInput
                                            label="Address"
                                            type="text"
                                            value={branch.address}
                                            onChange={(e) =>
                                                handleArrayFieldChange(
                                                    setBranches,
                                                    branchIndex,
                                                    'address',
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </MDBCol>
                                </MDBRow>

                                <h6 className="mt-3">Availability</h6>
                                {branch.availability.map((item, index) => (
                                    <MDBRow key={index} className="mb-2">
                                        <MDBCol md="4">
                                            <MDBInput
                                                label="Day"
                                                type="text"
                                                value={item.day}
                                                onChange={(e) =>
                                                    handleArrayFieldChange(
                                                        setBranches,
                                                        branchIndex,
                                                        'availability',
                                                        [
                                                            ...branch.availability.slice(0, index),
                                                            { ...item, day: e.target.value },
                                                            ...branch.availability.slice(index + 1),
                                                        ]
                                                    )
                                                }
                                            />
                                        </MDBCol>
                                        <MDBCol md="4">
                                            <MDBInput
                                                label="Start Time"
                                                type="time"
                                                value={item.start}
                                                onChange={(e) =>
                                                    handleArrayFieldChange(
                                                        setBranches,
                                                        branchIndex,
                                                        'availability',
                                                        [
                                                            ...branch.availability.slice(0, index),
                                                            { ...item, start: e.target.value },
                                                            ...branch.availability.slice(index + 1),
                                                        ]
                                                    )
                                                }
                                            />
                                        </MDBCol>
                                        <MDBCol md="4">
                                            <MDBInput
                                                label="End Time"
                                                type="time"
                                                value={item.end}
                                                onChange={(e) =>
                                                    handleArrayFieldChange(
                                                        setBranches,
                                                        branchIndex,
                                                        'availability',
                                                        [
                                                            ...branch.availability.slice(0, index),
                                                            { ...item, end: e.target.value },
                                                            ...branch.availability.slice(index + 1),
                                                        ]
                                                    )
                                                }
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                ))}
                                <MDBBtn
                                    color="info"
                                    size="sm"
                                    onClick={() =>
                                        handleArrayFieldChange(
                                            setBranches,
                                            branchIndex,
                                            'availability',
                                            [
                                                ...branch.availability,
                                                { day: '', start: '', end: '' },
                                            ]
                                        )
                                    }
                                >
                                    <MDBIcon fas icon="plus-circle" className="me-2" />
                                    Add Availability
                                </MDBBtn>

                                <h6 className="mt-3">Break Hours</h6>
                                {branch.breakHours.map((item, index) => (
                                    <MDBRow key={index} className="mb-2">
                                        <MDBCol md="4">
                                            <MDBInput
                                                label="Day"
                                                type="text"
                                                value={item.day}
                                                onChange={(e) =>
                                                    handleArrayFieldChange(
                                                        setBranches,
                                                        branchIndex,
                                                        'breakHours',
                                                        [
                                                            ...branch.breakHours.slice(0, index),
                                                            { ...item, day: e.target.value },
                                                            ...branch.breakHours.slice(index + 1),
                                                        ]
                                                    )
                                                }
                                            />
                                        </MDBCol>
                                        <MDBCol md="4">
                                            <MDBInput
                                                label="Start Time"
                                                type="time"
                                                value={item.start}
                                                onChange={(e) =>
                                                    handleArrayFieldChange(
                                                        setBranches,
                                                        branchIndex,
                                                        'breakHours',
                                                        [
                                                            ...branch.breakHours.slice(0, index),
                                                            { ...item, start: e.target.value },
                                                            ...branch.breakHours.slice(index + 1),
                                                        ]
                                                    )
                                                }
                                            />
                                        </MDBCol>
                                        <MDBCol md="4">
                                            <MDBInput
                                                label="End Time"
                                                type="time"
                                                value={item.end}
                                                onChange={(e) =>
                                                    handleArrayFieldChange(
                                                        setBranches,
                                                        branchIndex,
                                                        'breakHours',
                                                        [
                                                            ...branch.breakHours.slice(0, index),
                                                            { ...item, end: e.target.value },
                                                            ...branch.breakHours.slice(index + 1),
                                                        ]
                                                    )
                                                }
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                ))}
                                <MDBBtn
                                    color="info"
                                    size="sm"
                                    onClick={() =>
                                        handleArrayFieldChange(
                                            setBranches,
                                            branchIndex,
                                            'breakHours',
                                            [
                                                ...branch.breakHours,
                                                { day: '', start: '', end: '' },
                                            ]
                                        )
                                    }
                                >
                                    <MDBIcon fas icon="plus-circle" className="me-2" />
                                    Add Break Hour
                                </MDBBtn>

                                <h6 className="mt-3">Holiday Hours</h6>
                                {branch.holidayHours.map((item, index) => (
                                    <MDBRow key={index} className="mb-2">
                                        <MDBCol md="4">
                                            <MDBInput
                                                label="Day"
                                                type="text"
                                                value={item.day}
                                                onChange={(e) =>
                                                    handleArrayFieldChange(
                                                        setBranches,
                                                        branchIndex,
                                                        'holidayHours',
                                                        [
                                                            ...branch.holidayHours.slice(0, index),
                                                            { ...item, day: e.target.value },
                                                            ...branch.holidayHours.slice(index + 1),
                                                        ]
                                                    )
                                                }
                                            />
                                        </MDBCol>
                                        <MDBCol md="4">
                                            <MDBInput
                                                label="Start Time"
                                                type="time"
                                                value={item.start}
                                                onChange={(e) =>
                                                    handleArrayFieldChange(
                                                        setBranches,
                                                        branchIndex,
                                                        'holidayHours',
                                                        [
                                                            ...branch.holidayHours.slice(0, index),
                                                            { ...item, start: e.target.value },
                                                            ...branch.holidayHours.slice(index + 1),
                                                        ]
                                                    )
                                                }
                                            />
                                        </MDBCol>
                                        <MDBCol md="4">
                                            <MDBInput
                                                label="End Time"
                                                type="time"
                                                value={item.end}
                                                onChange={(e) =>
                                                    handleArrayFieldChange(
                                                        setBranches,
                                                        branchIndex,
                                                        'holidayHours',
                                                        [
                                                            ...branch.holidayHours.slice(0, index),
                                                            { ...item, end: e.target.value },
                                                            ...branch.holidayHours.slice(index + 1),
                                                        ]
                                                    )
                                                }
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                ))}
                                <MDBBtn
                                    color="info"
                                    size="sm"
                                    onClick={() =>
                                        handleArrayFieldChange(
                                            setBranches,
                                            branchIndex,
                                            'holidayHours',
                                            [
                                                ...branch.holidayHours,
                                                { day: '', start: '', end: '' },
                                            ]
                                        )
                                    }
                                >
                                    <MDBIcon fas icon="plus-circle" className="me-2" />
                                    Add Holiday Hour
                                </MDBBtn>

                                <MDBBtn
                                    color="success"
                                    size="sm"
                                    className="mt-3"
                                    onClick={() => handleBranchUpdate(branch._id, branch)}
                                >
                                    Update Branch
                                </MDBBtn>{' '}
                                <MDBBtn
                                    color="danger"
                                    size="sm"
                                    className="mt-3"
                                    onClick={() => handleBranchDelete(branch._id)}
                                >
                                    Delete Branch
                                </MDBBtn>
                            </MDBCardBody>
                        </MDBCard>
                    ))}


                    

                    {error && <p className="text-danger mt-3">{error}</p>}
                    {successMessage && <p className="text-success mt-3">{successMessage}</p>}
                </MDBContainer>
            </div>
        </>
    );
}

export default EditStore;
