import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBInput,
    MDBBtn,
} from 'mdb-react-ui-kit';
import { api } from '../services';

function AddNewStore() {
    const [storeName, setStoreName] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    const handleSave = async () => {
        if (!storeName || !email) {
            setError('Both Store Name and Email are required.');
            return;
        }

        try {
            const token = localStorage.getItem('token'); // Get token from localStorage
            const response = await api.post(
                '/stores',
                {
                    name: storeName,
                    email,
                },
                {
                    headers: { Authorization: `Bearer ${token}` }, // Include token in headers
                }
            );

            setSuccessMessage('Store added successfully.');
            setError('');
            setTimeout(() => navigate('/stores/my'), 2000); // Redirect to user's stores after success
        } catch (err) {
            setError(err.response?.data?.message || 'Failed to add store.');
            setSuccessMessage('');
        }
    };

    const handleCancel = () => {
        navigate('/stores/my');
    };

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2 className="mb-4">Add New Store</h2>
                    <MDBCard>
                        <MDBCardBody>
                            <MDBRow className="mb-3">
                                <MDBCol md="6">
                                    <MDBInput
                                        label="Store Name"
                                        id="storeName"
                                        type="text"
                                        value={storeName}
                                        onChange={(e) => setStoreName(e.target.value)}
                                    />
                                </MDBCol>
                                <MDBCol md="6">
                                    <MDBInput
                                        label="Email"
                                        id="email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </MDBCol>
                            </MDBRow>

                            {error && <p className="text-danger">{error}</p>}
                            {successMessage && <p className="text-success">{successMessage}</p>}

                            <MDBRow className="mt-4">
                                <MDBCol>
                                    <MDBBtn color="secondary" outline onClick={handleCancel}>
                                        Cancel
                                    </MDBBtn>
                                    <MDBBtn color="primary" className="ms-2" onClick={handleSave}>
                                        Save
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </div>
        </>
    );
}

export default AddNewStore;
