import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBInput,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBBadge,
} from 'mdb-react-ui-kit';
import { api } from '../services';

function StoreBranchOverview() {
    const [viewMode, setViewMode] = useState('list');
    const [storesData, setStoresData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchUserStores();
    }, []);

    const fetchUserStores = async () => {
        try {
            const token = localStorage.getItem('token'); // Get the token from localStorage
            const response = await api.get('/stores/my', {
                headers: { Authorization: `Bearer ${token}` }, // Attach token in headers
            });
            setStoresData(response.data.stores || []);
        } catch (error) {
            console.error('Error fetching stores:', error.response?.data || error.message);
        }
    };

    const handleViewModeChange = (mode) => setViewMode(mode);

    const handleSearchChange = (e) => setSearchQuery(e.target.value);

    const handleEditClick = (storeId) => {
        navigate('/edit-store', { state: { storeId } });
    };

    const handleManageBranchesClick = (storeId, storeName) => {
        navigate('/manage-branches', { state: { storeId, storeName } });
    };

    const handleAddStoreClick = () => {
        navigate('/add-store'); // Add new store
    };

    const getStatusBadge = (status) => {
        switch (status) {
            case 'Active':
                return <MDBBadge color="success">Active</MDBBadge>;
            case 'Inactive':
                return <MDBBadge color="danger">Inactive</MDBBadge>;
            case 'Under Review':
                return <MDBBadge color="warning">Under Review</MDBBadge>;
            default:
                return <MDBBadge color="secondary">{status}</MDBBadge>;
        }
    };

    const filteredStores = storesData.filter((store) =>
        store.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2 className="mb-4">My Stores & Branches</h2>

                    {/* Statistics Section */}
                    <MDBRow className="mb-4 g-4">
                        <MDBCol md="4">
                            <MDBCard className="stat-card h-100">
                                <MDBCardBody>
                                    <h6>Total Stores</h6>
                                    <h3 className="mb-0">{storesData.length}</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="4">
                            <MDBCard className="stat-card h-100">
                                <MDBCardBody>
                                    <h6>Total Branches</h6>
                                    <h3 className="mb-0">
                                        {storesData.reduce((acc, store) => acc + store.branches.length, 0)}
                                    </h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>

                    {/* Quick Actions */}
                    <MDBRow className="mb-4">
                        <h6>Quick Actions</h6>
                        <MDBCol>
                            <MDBBtn
                                color="primary"
                                className="quick-action-btn"
                                onClick={handleAddStoreClick}
                            >
                                Add New Store
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>

                    {/* Search and View Toggle */}
                    <MDBRow className="mb-4 d-flex align-items-center">
                        <MDBCol md="6">
                            <MDBInput
                                type="text"
                                placeholder="Search Stores"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </MDBCol>
                        <MDBCol md="6" className="d-flex justify-content-end align-items-center">
                            <span className="me-2">Sort by</span>
                            <MDBDropdown>
                                <MDBDropdownToggle color="primary">Recent</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link>Recent</MDBDropdownItem>
                                    <MDBDropdownItem link>Oldest</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                            <div className="ms-3">
                                <MDBBtn
                                    color="light"
                                    onClick={() => handleViewModeChange('list')}
                                    active={viewMode === 'list'}
                                >
                                    List
                                </MDBBtn>
                                <MDBBtn
                                    color="light"
                                    onClick={() => handleViewModeChange('grid')}
                                    active={viewMode === 'grid'}
                                >
                                    Grid
                                </MDBBtn>
                            </div>
                        </MDBCol>
                    </MDBRow>

                    {/* Store Content */}
                    {viewMode === 'grid' ? (
                        <MDBRow>
                            {filteredStores.map((store) => (
                                <MDBCol md="4" key={store._id}>
                                    <MDBCard className="order-card h-100">
                                        <MDBCardBody className="position-relative">
                                            <h5 className="mb-1">{store.name}</h5>
                                            <small className="text-muted">{store.email}</small>
                                            <div className="text-center my-3">
                                                <h6>{store.branches.length} Branches</h6>
                                            </div>
                                            <div className="d-flex justify-content-between mt-3">
                                                <MDBBtn
                                                    color="link"
                                                    size="sm"
                                                    onClick={() => handleEditClick(store._id)}
                                                >
                                                    Edit Store
                                                </MDBBtn>
                                                <MDBBtn
                                                    color="link"
                                                    size="sm"
                                                    onClick={() =>
                                                        handleManageBranchesClick(store._id, store.name)
                                                    }
                                                >
                                                    Manage Branches
                                                </MDBBtn>
                                            </div>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            ))}
                        </MDBRow>
                    ) : (
                        <MDBTable align="middle" responsive>
                            <MDBTableHead className="table-header">
                                <tr>
                                    <th className="custom-th">#</th>
                                    <th className="custom-th">Store Name</th>
                                    <th className="custom-th">Email</th>
                                    <th className="custom-th">Branches</th>
                                    <th className="custom-th">Actions</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody className="table-row">
                                {filteredStores.map((store, index) => (
                                    <tr key={store._id}>
                                        <td>{index + 1}</td>
                                        <td>{store.name}</td>
                                        <td>{store.email}</td>
                                        <td>{store.branches.length}</td>
                                        <td>
                                            <MDBBtn
                                                color="secondary"
                                                outline
                                                size="sm"
                                                className="me-2"
                                                onClick={() => handleEditClick(store._id)}
                                            >
                                                Edit Store
                                            </MDBBtn>
                                            <MDBBtn
                                                color="info"
                                                outline
                                                size="sm"
                                                onClick={() =>
                                                    handleManageBranchesClick(store._id, store.name)
                                                }
                                            >
                                                Manage Branches
                                            </MDBBtn>
                                        </td>
                                    </tr>
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                    )}
                </MDBContainer>
            </div>
        </>
    );
}

export default StoreBranchOverview;
