import React, { useState, useEffect } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBIcon,
    MDBInputGroup,
    MDBInput,
} from 'mdb-react-ui-kit';
import { Bar } from 'react-chartjs-2'; // Import Bar for the bar chart
import { api } from '../services';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';

function Dashboard() {
    const [search, setSearch] = useState('');
    const [stats, setStats] = useState({
        todaysOrders: 0,
        pendingOrders: 0,
        completedOrders: 0,
        revenue: 0,
    });
    const [chartData, setChartData] = useState(null);
    const [error, setError] = useState({ stats: null, chart: null });

    useEffect(() => {
        fetchDashboardStats();
    }, []);

    const fetchDashboardStats = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get('/dashboard/stats', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setStats(response.data);
            setError((prev) => ({ ...prev, stats: null }));
            updateChartData(response.data);
        } catch (error) {
            console.error('Error fetching dashboard stats:', error.message);
            setError((prev) => ({ ...prev, stats: error.message }));
        }
    };

    const updateChartData = (data) => {
        // Prepare data for the bar chart
        const labels = ['Orders']; // Single category for total orders
        const datasets = [
            {
                label: 'Completed Orders',
                data: [data.completedOrders],
                backgroundColor: 'rgba(75, 192, 192, 0.8)', // Green color
            },
            {
                label: 'Pending Orders',
                data: [data.pendingOrders],
                backgroundColor: 'rgba(255, 206, 86, 0.8)', // Yellow color
            },
            {
                label: 'Total Orders',
                data: [data.todaysOrders],
                backgroundColor: 'rgba(54, 162, 235, 0.8)', // Blue color
            },
        ];
        setChartData({ labels, datasets });
    };

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h2>Dashboard</h2>
                        <MDBInputGroup className="search-input" style={{ maxWidth: '300px' }}>
                            <MDBInput
                                type="text"
                                placeholder="Search"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                style={{ borderRadius: '8px' }}
                            />
                        </MDBInputGroup>
                    </div>

                    {/* Error Messages */}
                    {error.stats && <p style={{ color: 'red' }}>Error: {error.stats}</p>}

                    {/* Statistics Section */}
                    <MDBRow className="mb-4 g-4">
                        <MDBCol md="3">
                            <MDBCard className="stat-card h-100">
                                <MDBCardBody className="d-flex justify-content-between align-items-start">
                                    <div>
                                        <h6>Today's Orders</h6>
                                        <h3 className="mb-0">{stats.todaysOrders}</h3>
                                    </div>
                                    <MDBIcon fas icon="box" className="stat-icon" />
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBCard className="stat-card h-100">
                                <MDBCardBody className="d-flex justify-content-between align-items-start">
                                    <div>
                                        <h6>Pending Orders</h6>
                                        <h3 className="mb-0">{stats.pendingOrders}</h3>
                                    </div>
                                    <MDBIcon fas icon="clock" className="stat-icon" />
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBCard className="stat-card h-100">
                                <MDBCardBody className="d-flex justify-content-between align-items-start">
                                    <div>
                                        <h6>Completed Orders</h6>
                                        <h3 className="mb-0">{stats.completedOrders}</h3>
                                    </div>
                                    <MDBIcon fas icon="check-circle" className="stat-icon" />
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBCard className="stat-card h-100">
                                <MDBCardBody className="d-flex justify-content-between align-items-start">
                                    <div>
                                        <h6>Revenue</h6>
                                        <h3 className="mb-0">{stats.revenue} SAR</h3>
                                    </div>
                                    <MDBIcon fas icon="dollar-sign" className="stat-icon" />
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>

                    {/* Bar Chart Section */}
                    <MDBRow className="mb-4">
                        <MDBCol>
                            <MDBCard>
                                <MDBCardBody>
                                    <h5>Order Overview</h5>
                                    {chartData ? (
                                        <Bar
                                            data={chartData}
                                            options={{
                                                plugins: {
                                                    legend: { position: 'top' },
                                                },
                                                responsive: true,
                                            }}
                                        />
                                    ) : (
                                        <p>Loading chart...</p>
                                    )}
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        </>
    );
}

export default Dashboard;
