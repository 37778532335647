import React, { useState } from 'react';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBInput,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
} from 'mdb-react-ui-kit';

function Expenses() {
    const [selectedApp, setSelectedApp] = useState('Uber Eats');
    const [selectedBranch, setSelectedBranch] = useState('Select Branch');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [sortOrder, setSortOrder] = useState('recent');

    // Mock data for expenses
    const expensesData = [
        { id: "#ABC456", type: "Uber Eats", orderAmount: "$74.56", commission: "$14.40", dateTime: "2023-09-23T12:30:00" },
        { id: "#ABC789", type: "Uber Eats", orderAmount: "$96.45", commission: "$18.96", dateTime: "2023-09-23T15:00:00" },
        { id: "#ABC369", type: "Deliveroo Foodpanda", orderAmount: "$36.23", commission: "$5.60", dateTime: "2023-09-23T10:30:00" },
        { id: "#DEF123", type: "Uber Eats", orderAmount: "$120.50", commission: "$24.10", dateTime: "2023-09-24T13:45:00" },
        { id: "#DEF456", type: "Deliveroo Foodpanda", orderAmount: "$50.00", commission: "$10.00", dateTime: "2023-09-24T11:15:00" },
    ];

    // Filter and sort expenses
    const filteredExpenses = expensesData
        .filter((expense) => {
            const matchesSearch = expense.id.toLowerCase().includes(searchQuery.toLowerCase());
            const matchesApp = selectedApp === 'Select App' || expense.type.includes(selectedApp);
            const matchesBranch = selectedBranch === 'Select Branch' || selectedBranch === 'Branch 1'; // Adjust for real branch filtering
            const matchesDate = !selectedDate || expense.dateTime.startsWith(selectedDate);
            return matchesSearch && matchesApp && matchesBranch && matchesDate;
        })
        .sort((a, b) => {
            if (sortOrder === 'recent') {
                return new Date(b.dateTime) - new Date(a.dateTime);
            } else {
                return new Date(a.dateTime) - new Date(b.dateTime);
            }
        });

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2 className="mb-4" style={{ color: "#333" }}>All Expenses</h2>

                    {/* Summary Section */}
                    <MDBRow className="mb-4">
                        <MDBCol md="3">
                            <MDBCard className="h-100">
                                <MDBCardBody>
                                    <h6>Total Orders</h6>
                                    <h3>{expensesData.length}</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBCard className="h-100">
                                <MDBCardBody>
                                    <h6>Completed Orders</h6>
                                    <h3>{filteredExpenses.length}</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBCard className="h-100">
                                <MDBCardBody>
                                    <h6>Total Value</h6>
                                    <h3>${filteredExpenses.reduce((sum, e) => sum + parseFloat(e.orderAmount.slice(1)), 0).toFixed(2)}</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBCard className="h-100">
                                <MDBCardBody>
                                    <h6>Total Commission</h6>
                                    <h3>${filteredExpenses.reduce((sum, e) => sum + parseFloat(e.commission.slice(1)), 0).toFixed(2)}</h3>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>

                    {/* Filters Section */}
                    <MDBRow className="align-items-center mb-4">
                        <MDBCol md="3">
                            <MDBDropdown>
                                <MDBDropdownToggle color="primary" style={{ borderRadius: "8px" }}>
                                    {selectedApp}
                                </MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link onClick={() => setSelectedApp('Uber Eats')}>Uber Eats</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={() => setSelectedApp('Deliveroo Foodpanda')}>Deliveroo Foodpanda</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={() => setSelectedApp('Select App')}>All Apps</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBDropdown>
                                <MDBDropdownToggle color="primary" style={{ borderRadius: "8px" }}>
                                    {selectedBranch}
                                </MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem link onClick={() => setSelectedBranch('Branch 1')}>Branch 1</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={() => setSelectedBranch('Branch 2')}>Branch 2</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={() => setSelectedBranch('Select Branch')}>All Branches</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBInput
                                type="date"
                                value={selectedDate}
                                onChange={(e) => setSelectedDate(e.target.value)}
                                style={{ borderRadius: "8px" }}
                            />
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBInput
                                type="text"
                                placeholder="Search Order"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                style={{ borderRadius: "8px" }}
                            />
                        </MDBCol>
                    </MDBRow>

                    {/* Orders Table */}
                    <MDBTable responsive>
                        <MDBTableHead className="bg-light">
                            <tr>
                                <th>Order ID</th>
                                <th>Type</th>
                                <th>Order Amount</th>
                                <th>Commission</th>
                                <th>Date & Time</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {filteredExpenses.map((expense, index) => (
                                <tr key={index}>
                                    <td>{expense.id}</td>
                                    <td>{expense.type}</td>
                                    <td>{expense.orderAmount}</td>
                                    <td>{expense.commission}</td>
                                    <td>{new Date(expense.dateTime).toLocaleString()}</td>
                                </tr>
                            ))}
                        </MDBTableBody>
                    </MDBTable>
                </MDBContainer>
            </div>
        </>
    );
}

export default Expenses;
